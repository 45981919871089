import { observeElement } from '@uvocore-front/utils';
import './style.scss';

window.addEventListener('DOMContentLoaded', () => {
  /**
   *  Inline Images Observer
   */
  if (document.querySelector('[data-observed-image]')) {
    observeElement({
      element: '[data-observed-image]',
      marginValue: 300,
      callback: (img) => {
        const src = img.getAttribute('data-src');
        const srcset = img.getAttribute('data-srcset');

        if (src) img.src = src; /* eslint-disable-line */
        if (srcset) img.srcset = srcset; /* eslint-disable-line */
      },
    });
  }

  /**
   *  Background Images Observer
   */
  if (document.querySelector('[data-observed-bg]')) {
    observeElement({
      element: '[data-observed-bg]',
      marginValue: 300,
      callback: (bgImg) => {
        bgImg.classList.add('load-bg');
      },
    });
  }
});
